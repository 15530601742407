import React from "react"
import {
    getGoButtonLink,
    goToUrl,
} from '../../services/links'
import './styles.scss';

interface GoProps {
    reference: string,
    url: string
    lang: string
}

const Go: React.FC<GoProps> = ({ reference, url, lang }) => {

    const getTitle = () => {
        if (lang === 'es') {
            return 'Estamos verificando que no eres un robot';
        }

        return 'We are verifying that you are not a robot';
    }

    const getButtonText = () => {
        if (lang === 'es') {
            return 'Continuar';
        }

        return 'Continue';
    }

    const getLink = () => {
        return getGoButtonLink(url, reference);
    }

    const goToLink = () => {
        const link = getLink();
        goToUrl(link);
    }

    return (
        <div className="go">
            <div className="container mx-auto px-4 text-center h-full w-full">
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <h1 className="text-3xl font-extrabold text-white tracking-tight md:text-4xl mb-10">{getTitle()}</h1>
                    <span onClick={() => goToLink()} className="w-full md:w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 cursor-pointer">{getButtonText()}</span>
                </div>
            </div>
        </div>
    )
}
export default Go
