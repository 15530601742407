import React, { useEffect, useState } from "react"
import Go from "../../components/Go"
import Layout from "../../components/Layout/Layout"
import {
  isValidV4UUID,
  canEnter,
  getParameterByName,
  goToHome,
  getNavigatorLanguage,
  removeQueryStringFromURL
} from '../../services/links'

const GoTemplate = () => {
  const [loading, setLoading] = useState(true)
  const [reference, setReference] = useState('')
  const [url, setUrl] = useState('')
  const [lang, setLang] = useState('')
  // https://href.li/?http://localhost:8000/go/?ref=c5c6c8d7-7e68-47fc-bc0f-3a0b99208874&u=https://bit.ly/3jNzJDF
  // https://href.li/?https://602825d9b3c4ac9582075b83--sanity-god-blog.netlify.app/go/?ref=c5c6c8d7-7e68-47fc-bc0f-3a0b99208874&u=aHR0cHM6Ly9iaXQubHkvM2pOekpERg%3D%3D
  
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const ref = getParameterByName('ref');
      const u = getParameterByName('u');
      removeQueryStringFromURL()
      setReference(ref);
      setUrl(u);

      if (!ref || !u || !isValidV4UUID(ref) || !canEnter(ref)) {
        goToHome();
      } else {
        setLang(getNavigatorLanguage());
        setLoading(false)
      }
    }
  }, []);

  return (
    <Layout>
      { !loading &&
        <Go
        reference={reference}
        url={url}
        lang={lang}
      />
      }
      {loading && <div>Loading...</div>}
    </Layout>
  )
}

export default GoTemplate
